import { slide as Slide } from 'react-burger-menu'
import React from 'react';
import { Link } from "gatsby"
import "../styles/menu.scss"

class Menu extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };
    }

      // This keeps your state in sync with the opening/closing of the menu
  // via the default means, e.g. clicking the X, pressing the ESC key etc.
  handleStateChange (state) {
    this.setState({isOpen: state.isOpen})  
  }

    render() {
        // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
        return (
            <Slide right isOpen={ this.state.isOpen }  onStateChange={(state) => this.handleStateChange(state)} pageWrapId={ "main" }>
                <Link onClick={this.close} to="/#vision">Vision</Link>
                <Link onClick={this.close} to="/#people">People</Link>
                <Link onClick={this.close} to="/#ambition">Ambition</Link>
                <Link onClick={this.close} to="/#community">Community</Link>
                <Link onClick={this.close} to="/#careers">Careers</Link>
                <span className="spacer menu-spacer"></span>
                <Link to="/privacy">Privacy</Link>
                <Link to="/terms">Terms & Conditions</Link>
                <Link to="/support">Support</Link>
            </Slide>
        );
    }

    close = (event) =>  {  
        // event.preventDefault();
        this.setState({isOpen: false})
    };
    // open = (state) =>  state.isOpen;
}

export default Menu