import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import {NavLinks} from "./nav-links"
import '../styles/header.scss'

const Header = ({ pathname }) => (
  <header>
    <div className="logo">
      <Link to="/">
        S&nbsp;Ͼ&nbsp;R&nbsp;Y&nbsp;E&nbsp;R
      </Link>
    </div>
    
    <NavLinks className="links" pathname={pathname} links={[ 'vision', 'people','ambition','community','careers' ]} />
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
