/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import Menu from "./menu"
import "../styles/site.css"
import "../styles/layout.scss"
import { Link } from "gatsby"

const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header pathname={location.pathname} />
      <Menu className="menu"></Menu>
      <main id="main">
        {children}
      </main>

      <footer>
        <span>© {new Date().getFullYear()} Scryer Limited</span>
        <div className="bottomLinks">
          <Link to="/privacy">Privacy Policy</Link>
          <Link to="/terms">Terms & Conditions</Link>
          <Link to="/support">Support</Link>
        </div>
      </footer>
    </>
  )
}

export default Layout
