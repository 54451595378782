import React from "react"
import "../styles/nav-links.scss"
import { Link } from "gatsby"

export class NavLinks extends React.Component {

    constructor(props) {
        super(props);

        const margin = this.getDefaultMargin(props.links, this.props.pathname);
        const navFound = margin !== -1;

        this.state = {
            links: props.links,
            underlineMargin: navFound ? margin : 0,
            hideLine: !navFound
        };
    }

    render() {
        const linkNumber = this.state.links.length;

        return (
            <ul className="navLinks">
                {this.state.links.map((link, i) =>
                    <li key={i}>
                        <Link
                            to={'/#' + link}
                            style={{ width: 100 / linkNumber + '%' }}
                            onMouseOver={(e) => this.navSelected(i)}
                            onMouseLeave={(e) => this.navDeselected(this.state.links)}>
                            {link}
                        </Link>
                    </li>)}
                    <hr style={{
                    width: (100 / linkNumber + '%'),
                    marginLeft: this.state.underlineMargin,
                }}
                    className={this.state.hideLine ? 'hidden' : ''}
                />
            </ul>
        )
    }
    getMargin(links, i) {
        const relativeLength = links.length / (i);
        const width = (100 / relativeLength) + '%';
        return width;
    }
    getDefaultMargin(links, pathname = null) {
        const page = pathname ? pathname.split('/')[1] : window.location.pathname.split('/')[1];
        let index = links.findIndex(l => l === page);
        if (index === -1) { return -1; }
        const margin = this.getMargin(links, index);
        return margin;
    }

    navDeselected(links) {
        const margin = this.getDefaultMargin(links);
        const navFound = margin !== -1;
        this.setState(state => ({
            underlineMargin: navFound ? margin : 0,
            hideLine: true
        }));
    }
    navSelected(i) {
        const width = this.getMargin(this.state.links, i);
        this.setState(state => ({
            underlineMargin: width,
            hideLine: false,
        }));
    }
}
